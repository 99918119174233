<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="1000px"
    title="添加问卷"
    :append-to-body="true"
    :modal-append-to-body="false"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="120px"
    >
      <b-row
        :gutter="20"
        class="mr10"
      >
        <b-col md="6">
          <el-form-item
            label="模板名称"
            prop="name"
            :rules="[ { required: true, message: '模板名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.name"
              placeholder="请输入模板名称"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="评估类型"
            prop="assessCategoryCode"
            :rules="[ {required: true, message: '评估类型不能为空', trigger: 'blur' }]"
          >
            <SelectTree
              v-model="form.assessCategoryCode"
              :props="treeSelectProps"
              :options="assessCategoryOptions"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="子标题名称"
            prop="subtitle"
            :rules="[ { required: true, message: '子标题名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.subtitle"
              placeholder="请输入子标题名称"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="编号"
            prop="code"
            :rules="[ { required: true, message: '编号不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.code"
              :disabled="operateType===2"
              placeholder="请输入编号"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="排序号">
            <el-input
              v-model.number="form.sortNo"
              type="number"
              placeholder="请输入排序号"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="状态">
            <el-select
              v-model="form.status"
              placeholder="请选择状态"
            >
              <el-option
                v-for="(item,index) in [{label: '请选择状态',value: 0},{label:'启用',value: 1},{label:'停用',value:2}]"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
<!--        <b-col md="6">-->
<!--          <el-form-item-->
<!--            label="是否审核"-->
<!--            prop="isProcess"-->
<!--            :rules="[ { required: true, message: '是否审核不能为空', trigger: 'change' }]"-->
<!--          >-->
<!--            <el-radio-group v-model="form.isProcess">-->
<!--              <el-radio :label="true">-->
<!--                是-->
<!--              </el-radio>-->
<!--              <el-radio :label="false">-->
<!--                否-->
<!--              </el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
<!--        </b-col>-->
<!--        <b-col md="6">-->
<!--          <el-form-item-->
<!--              label="是否外部填写"-->
<!--              prop="isOutsidersWriter"-->
<!--              :rules="[ { required: true, message: '是否外部填写不能为空', trigger: 'change' }]"-->
<!--          >-->
<!--            <el-radio-group v-model="form.isOutsidersWriter">-->
<!--              <el-radio :label="true">-->
<!--                是-->
<!--              </el-radio>-->
<!--              <el-radio :label="false">-->
<!--                否-->
<!--              </el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
<!--        </b-col>-->
        <b-col md="6">
          <el-form-item
            label="风险控制"
            prop="riskPloy"
          >
            <el-select
              v-model="form.riskPloy"
              placeholder="请选择风险控制"
            >
              <el-option
                v-for="(item,index) in riskPloyOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="模板描述">
            <el-input
              v-model="form.remarks"
              type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="评估对象"
            prop="assessTarget"
            :rules="[ { required: true, message: '评估类型不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.assessTarget"
              placeholder="请选择评估对象"
            >
              <el-option
                v-for="(item,index) in assessTargetOption"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="对象信息操作"
            prop="targetFieldOperate"
            :rules="[ { required: true, message: '评估类型不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.targetFieldOperate"
              placeholder="请选择所属业务"
            >
              <el-option
                v-for="(item,index) in [{label:'无',value:0},{label:'显示字段',value:1},{label:'修改字段',value:2}]"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="触发条件">
            <el-input
              v-for="(item,index) in form.conditionList"
              :key="index"
              v-model="item.val"
            >
              <template slot="append">
                <span
                  v-if="index === 0"
                  @click="form.conditionList.push({val: ''})"
                >
                  <i class="el-icon-circle-plus-outline cursor-pointer" />
                </span>
                <span
                  v-else
                  @click="form.conditionList.splice(index,1)"
                >
                  <i class="el-icon-remove-outline cursor-pointer" />
                </span>

              </template>
            </el-input>
          </el-form-item>

        </b-col>
        <b-col md="6">
          <el-form-item label="评估时机">
            <el-input
              v-for="(item,index) in form.timingList"
              :key="index"
              v-model="item.val"
            >
              <template slot="append">
                <i
                  v-if="index === 0"
                  class="el-icon-circle-plus-outline"
                  @click="form.timingList.push({val: ''})"
                />
                <i
                  v-else
                  class="el-icon-remove-outline"
                  @click="form.timingList.splice(1,index)"
                />
              </template>
            </el-input>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateQuestionnaire, GetAssessCategoryTree, GetDetailsInfo } from '@/api/assess/questionTemplate'
import SelectTree from '@core/components/select-tree/SelectTree.vue'

export default {
  components: { SelectTree },
  data() {
    return {
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'label', // 显示名称
        children: 'children', // 子级字段名
      },
      assessTypeOption: [],
      assessCategoryOptions: [],
      assessTargetOption: [
        { label: '无', value: 0 },
        { label: '组织', value: 1 },
        { label: '业务', value: 2 },
        { label: '第三方', value: 3 },
      ],
      riskPloyOptions: [
        { label: '无风险', value: 0 },
        { label: '同步到风险管理', value: 1 },
        { label: '不同步到风险管理', value: 2 },
      ],
      dialogVisible: false,
      countryProps: {
        emitPath: false,
      },
      loading: false,
      dbCode: '',
      operateType: 1,
      form: {
        assessCategoryCode: '', // 评估类型
        name: '',
        code: '',
        sortNo: 0,
        subtitle: '',
        status: 1,
        isProcess: true,
        isOutsidersWriter: false,
        remarks: '',
        sourceCode: '',
        assessTarget: '',
        targetFieldOperate: undefined,
        riskPloy: undefined,
        conditionList: [
          { val: '' },
        ],
        timingList: [
          { val: '' },
        ],
      },
    }
  },
  methods: {
    init(type, code, assessCategoryCode) {
      // 1新增 2修改 3复制
      this.getAssessCategoryTree()
      this.dialogVisible = true
      this.operateType = type
      this.$nextTick(() => {
        this.form.assessCategoryCode = assessCategoryCode
        if (type === 3 || type === 2) {
          this.dbCode = code
        }
        if (this.dbCode) {
          this.getDetail()
        }
      })
    },
    dialogClose() {
      this.dbCode = ''
      this.form = {
        assessCategoryCode: '', // 评估类型
        name: '',
        code: '',
        sortNo: 0,
        subtitle: '',
        status: 1,
        riskPloy: undefined,
        isOutsidersWriter: false,
        isProcess: 1,
        remarks: '',
        assessTarget: '',
        sourceCode: '',
        targetFieldOperate: undefined,
        conditionList: [
          { val: '' },
        ],
        timingList: [
          { val: '' },
        ],
      }
      this.$refs.form.resetFields()
    },
    dialogOpen() {

    },
    getDetail() {
      GetDetailsInfo(this.dbCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.operateType === 3) {
            this.form.sourceCode = this.form.code
            this.form.code = ''
          }
          if (this.form.status === 0) {
            this.form.status = undefined
          }
          const { conditionList } = this.form
          const { timingList } = this.form
          if (conditionList.length > 0) {
            this.form.conditionList = []
            conditionList.forEach(c => {
              this.form.conditionList.push({ val: c })
            })
          } else {
            this.form.conditionList = [{ val: '' }]
          }
          if (timingList.length > 0) {
            this.form.timingList = []
            timingList.forEach(c => {
              this.form.timingList.push({ val: c })
            })
          } else {
            this.form.timingList = [{ val: '' }]
          }
        }
      })
    },
    getAssessCategoryTree() {
      GetAssessCategoryTree().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assessCategoryOptions = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const submitData = {}
          Object.assign(submitData, this.form)
          submitData.status = submitData.status ? submitData.status : 0
          submitData.sortNo = submitData.sortNo ? submitData.sortNo : 0
          submitData.conditionList = []
          submitData.timingList = []
          if (this.form.conditionList.length > 0) {
            this.form.conditionList.forEach(item => {
              if (item.val.length > 0) {
                submitData.conditionList.push(item.val)
              }
            })
          }
          if (this.form.timingList.length > 0) {
            this.form.timingList.forEach(item => {
              if (item.val.length > 0) {
                submitData.timingList.push(item.val)
              }
            })
          }
          submitData.operateType = this.operateType
          saveOrUpdateQuestionnaire(submitData, this.operateType).then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          })
          this.loading = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
    .el-form-more {
        display: flex;
        justify-content: flex-start;
        .el-form-item {
            flex: 1;
        }
        .el-input--suffix {
            width: 80px;
        }
        .el-input--suffix .el-input__inner {
            padding-right: 0px;
        }
    }

    .no-label .el-form-item__content {
        margin-left: 10px !important;
    }

    .el-range-editor.is-active,
    .el-range-editor.is-active:hover,
    .el-select .el-input.is-focus .el-input__inner {
        /*border-color: transparent !important;*/
    }
</style>
