<template>
  <el-dialog
    :title="titleObj[saveType]"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    >
      <el-form-item
        label="类型编号"
        prop="code"
        :rules="[ { required: true, message: '类型编号不能为空', trigger: 'change' }]"
      >
        <el-input
          v-model="form.code"
          :disabled="saveType === 3"
        />
      </el-form-item>
      <el-form-item
        label="类型名称"
        prop="categoryName"
        :rules="[ { required: true, message: '类型名称不能为空', trigger: 'change' }]"
      >
        <el-input v-model="form.categoryName" />
      </el-form-item>
      <el-form-item
        label="是否审核"
        prop="isProcess"
        :rules="[ { required: true, message: '是否审核不能为空', trigger: 'change' }]"
      >
        <el-radio-group v-model="form.isProcess">
          <el-radio :label="true">
            是
          </el-radio>
          <el-radio :label="false">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="是否外部填写"
        prop="isOutsidersWriter"
        :rules="[ { required: true, message: '是否外部填写不能为空', trigger: 'change' }]"
      >
        <el-radio-group v-model="form.isOutsidersWriter">
          <el-radio :label="true">
            是
          </el-radio>
          <el-radio :label="false">
            否
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { saveOrUpdateType, GetAssessCategoryInfo } from '@/api/assess/questionTemplate'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      titleObj: {
        1: '新增一级类型',
        2: '新增子类',
        3: '修改类型',
      },
      form: {
        parentCode: '',
        categoryName: '',
        code: '',
        isProcess: true,
        isOutsidersWriter: false,
      },
      saveType: 1,
      groupOptions: [],
      dimensions: [],
    }
  },
  methods: {
    dialogOpen() {
      if (this.form.code) {
        this.getDetail()
      }
    },
    dialogClose() {
      this.form = {
        parentCode: '',
        categoryName: '',
        code: '',
        isProcess: true,
        isOutsidersWriter: false,
      }
      this.$refs.form.resetFields()
    },
    getDetail() {
      GetAssessCategoryInfo(this.form.code).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          saveOrUpdateType(this.form, this.saveType).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
