<template>
  <div>
    <b-row
      :gutter="20"
      class="match-height"
    >
      <b-col md="3">
        <b-card>
          <div class="filter-wrap flex-between">
            <h4>评估类型</h4>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="assessTypeDialogShow(1,null)"
            >
              新 增
            </el-button>
          </div>
          <div :style="{maxHeight: pageH -250 + 'px',overflowY: 'scroll'}">
            <el-tree
              ref="elTree"
              :highlight-current="true"
              :data="treeData"
              :default-expanded-keys="expandKeys"
              node-key="id"
              :expand-on-click-node="false"
              :props="defaultProps"
              @current-change="treeNodeClick"
            >
              <span
                slot-scope="{ node, data }"
                class="field-tree-node"
              >
                <span>{{ data.label }}({{ data.id }})</span>
                <span class="tree-operate">
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="assessTypeDialogShow(2,data)"
                  >
                    <el-tooltip
                      content="新增"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="assessTypeDialogShow(3,data)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click.stop="delNode(data,node)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card>
          <div
            class="flex-between text-right"
            style="margin-bottom: 10px"
          >
            <div class="flex-start">
              <div class=" filter-item">
                <el-input
                  v-model="listQuery.SearchStr"
                  placeholder="请输入内容"
                  clearable
                  @clear="handleFilter"
                />
              </div>
              <el-button
                size="small"
                class="filter-item"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
              >
                搜索
              </el-button>
            </div>
            <div class="flex-end">
              <el-button
                style="margin-bottom: 10px;"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="importDialogShow"
              >
                导入
              </el-button>
              <el-button
                style="margin-bottom: 10px;"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="saveDialogShow(1,null)"
              >
                新增
              </el-button>
            </div>

          </div>
          <el-table
            v-loading="loading"
            fit
            border
            highlight-current-row
            :data="tableData"
            stripe
            style="width: 100%"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="name"
              label="模板名称"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              prop="code"
              label="模板编号"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              prop="subtitle"
              label="子标题"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              prop="remarks"
              label="模板描述"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              prop="assessCategoryName"
              label="评估类型"
            />
            <el-table-column
              width="80"
              prop="status"
              label="状态"
            >
              <template slot-scope="{row}">
                <el-switch
                  :value="row.status"
                  :active-value="1"
                  :inactive-value="2"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="updateStatus(row)"
                />
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="updateName"
              label="编辑人"
            />
            <el-table-column
              :show-overflow-tooltip="true"
              prop="updateTime"
              label="更新时间"
            />
            <el-table-column
              label="操作"
              width="200px"
            >
              <template slot-scope="{row}">
                <el-link
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(3,row.code)"
                >
                  <el-tooltip
                    content="复制"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document-copy" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="$router.push({ path: '/addQuestion', query:{ c: row.code}})"
                >
                  <el-tooltip
                    content="题目管理"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-circle-plus-outline" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="saveDialogShow(2, row.code)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="$router.push({ path: '/addQuestion', query:{ c: row.code,r: 1}})"
                >
                  <el-tooltip
                    content="预览"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  type="primary"
                  class="mr10"
                  @click="del(row)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  type="primary"
                  @click="$router.push({path: '/linkRisk',query:{ c: row.code,ac:row.assessCategoryCode}})"
                >
                  <el-tooltip
                    content="风险"
                    placement="top"
                    effect="light"
                  >
                    <i
                      style="color: #f56c6c"
                      class="el-icon-warning-outline"
                    />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            v-show="total>0"
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          />
        </b-card>
      </b-col>
    </b-row>
    <QuestionTemplateSaveDialog
      ref="saveDialog"
      @handleFilter="handleFilter"
    />
    <AssessTypeSave
      ref="assessTypeSave"
      @handleFilter="getAssessCategoryTree"
    />
    <ImportQuestion
      ref="importQuestion"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import {
  GetAssessCategoryTree, QuestionnairePage, DeleteAssessCategory, QuestionnaireCDelete, UpdateQusetionStatus,
} from '@/api/assess/questionTemplate'
import { error, success } from '@core/utils/utils'
import ImportQuestion from '@/views/assess/questionTemplate/ImportQuestion.vue'
import QuestionTemplateSaveDialog from './QuestionTemplateSaveDialog.vue'
import AssessTypeSave from './AssessTypeSave.vue'

export default {
  components: {
    pagination,
    QuestionTemplateSaveDialog,
    AssessTypeSave,
    ImportQuestion,
  },
  data() {
    return {
      loading: false,
      defaultProps: {
        children: 'children',
        label: 'label',
        parentId: 'parentId',
      },
      treeData: [],
      expandKeys: [],
      tableData: [],
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        assessCategoryCode: '',
      },
      total: 0,
      currentNode: {},
    }
  },
  created() {
    this.getAssessCategoryTree()
  },
  methods: {
    updateStatus(row) {
      const msgStr = row.status === 1 ? '确定停用吗' : '确定启用吗?'
      const status = row.status === 1 ? 2 : 1
      this.$confirm(msgStr, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        UpdateQusetionStatus({ code: row.code, status }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getList()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    delNode(data, node) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteAssessCategory(data).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getAssessCategoryTree()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    updateTreeNode(data) {
      this.currentNode.label = data.label
      this.currentNode.id = data.id
    },
    addTreeNode(data) {
      if (data.parentId === '') { // 添加一级分类
        this.treeData.push(data)
      } else {
        // 添加子分类
        if (!this.currentNode.children) {
          this.$set(this.currentNode, 'children', [])
        }
        this.currentNode.children.push(data)
      }
    },
    assessTypeDialogShow(type, data) {
      this.$refs.assessTypeSave.dialogVisible = true
      this.$refs.assessTypeSave.saveType = type
      if (data) {
        if (type === 3) {
          this.$refs.assessTypeSave.form.code = data.id
        }
        if (type === 2) {
          this.$refs.assessTypeSave.form.parentCode = data.id
        }
        this.currentNode = data
      }
    },
    treeNodeClick(data, node) {
      if (this.listQuery.assessCategoryCode === data.id) {
        this.listQuery.assessCategoryCode = ''
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.listQuery.assessCategoryCode = data.id
      }
      this.handleFilter()
    },
    getAssessCategoryTree() {
      GetAssessCategoryTree().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.expandKeys = []
          this.treeData = resData.data
          if (this.treeData.length > 0) {
            const currentNode = this.treeData[0].id
            this.listQuery.assessCategoryCode = currentNode
            this.expandKeys.push(this.treeData[0].id)
            this.$nextTick(() => {
              this.$refs.elTree.setCurrentKey(this.treeData[0].id)
            })
            this.getList()
          }
        }
      })
    },
    getList() {
      this.loading = true
      QuestionnairePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      })
    },
    del(row) {
      this.confirm('确认删除吗?', () => {
        QuestionnaireCDelete(row.code).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(res.data.msg)
            this.handleFilter()
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    importDialogShow() {
      this.$refs.importQuestion.dialogVisible = true
    },
    saveDialogShow(type, code) {
      this.$refs.saveDialog.init(type, code, this.listQuery.assessCategoryCode)
    },
  },
}
</script>

<style scoped lang="scss">
    .tree-operate{
        display: none;
        position: absolute;
        right: 0;
    }
    .field-tree-node{
        width: 100%;
        position: relative;
    }
  .field-tree-node:hover{
      .tree-operate{
          display: inline-block;
      }
  }
</style>
